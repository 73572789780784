import { Box, BoxProps, useTheme } from "@mui/material";
import { ReactElement, ReactNode } from "react";

type Props = {
  children: ReactNode;
  gap?: number;
  sx?: BoxProps["sx"];
};

export function VerticalContent({ gap, children, sx }: Props): ReactElement {
  const theme = useTheme();

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={gap ? gap : theme.spacing(3)}
      sx={sx}
    >
      {children}
    </Box>
  );
}
