import { Close } from "@mui/icons-material";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  styled,
} from "@mui/material";
import React, { ReactElement, ReactNode } from "react";

export type DialogCloseReason = "backdropClick" | "escapeKeyDown" | "iconClick";

type ExtendedMuiDialogProps = MuiDialogProps & {
  onClose: (
    event: React.MouseEvent<HTMLButtonElement>,
    reason: DialogCloseReason
  ) => void;
};

type DialogBaseProps = {
  title: string;
  children: ReactNode;
  showXClose?: boolean;
} & ExtendedMuiDialogProps;

export type DialogProps = {
  actions?: ReactNode;
  alert?: ReactNode;
} & DialogBaseProps;

export function Dialog({
  title,
  actions,
  alert,
  children,
  ...dialogProps
}: DialogProps): ReactElement {
  return (
    <DialogBase title={title} {...dialogProps}>
      <DialogContent>{children}</DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
      {alert ? (
        <Box mx={4} mb={4}>
          {alert}
        </Box>
      ) : null}
    </DialogBase>
  );
}

export function DialogBase({
  title,
  children,
  showXClose = true,
  onClose,
  ...rest
}: DialogBaseProps) {
  return (
    <StyledDialog fullWidth={true} maxWidth={"xs"} onClose={onClose} {...rest}>
      <DialogTitle variant="h1">{title}</DialogTitle>
      {showXClose && (
        <IconButton
          sx={{ position: "absolute", top: 20, right: 24 }}
          onClick={(e) => onClose(e, "iconClick")}
          size={"small"}
        >
          <Close fontSize={"small"} />
        </IconButton>
      )}
      {children}
    </StyledDialog>
  );
}

const StyledDialog = styled(MuiDialog)(({ theme }) => ({
  "& .MuiBackdrop-root": {
    background: theme.palette.grey[700],
  },
  "& .MuiDialog-paper": {
    borderRadius: "18px",
    maxWidth: "600px",
  },
  "& .MuiDialogTitle-root": {
    margin: 0,
    padding: theme.spacing(4, 9, 3, 4),
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(0, 4),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(4),
  },
}));
