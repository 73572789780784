import {
  Box,
  BoxProps,
  Divider,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { ReactElement, ReactNode } from "react";

type Props = {
  title: string;
  children: ReactNode;
} & BoxProps;

export function DownloadFileList({
  title,
  children,
  ...rest
}: Props): ReactElement {
  const theme = useTheme();
  return (
    <StyledBox {...rest}>
      <Box sx={{ p: 2, pt: 1, pb: 1, backgroundColor: theme.palette.grey[50] }}>
        <Typography variant="body3">{title}</Typography>
      </Box>
      <Divider />
      <Box p={2} pt={0}>
        {children}
      </Box>
    </StyledBox>
  );
}

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 14px;
`;
