import { Box, Link, Typography, styled } from "@mui/material";
import { ReactNode } from "react";

type Props = {
  icon?: ReactNode;
  title?: string;
  text?: string;
  supportPhone?: string;
  supportEmail?: string;
};

export function SidebarContacts({
  icon,
  title,
  text,
  supportPhone,
  supportEmail,
}: Props) {
  return (
    <StyledContactBox>
      <Box display="flex" alignItems="flex-start" mb={1}>
        {icon ? (
          icon
        ) : (
          <img
            src={"/assets/contact/phone.svg"}
            alt=""
            width={"20px"}
            style={{ marginRight: "8px", marginTop: "4px" }}
          />
        )}
        <StyledContactDetails>
          {title && <StyledContactTextBold>{title}</StyledContactTextBold>}
          {text && <StyledContactText>{text}</StyledContactText>}
          {supportPhone && (
            <StyledContactLink href={`tel:${supportPhone}`}>
              {supportPhone}
            </StyledContactLink>
          )}
          {supportEmail && (
            <StyledContactLink href={`mailto:${supportEmail}`}>
              {supportEmail}
            </StyledContactLink>
          )}
        </StyledContactDetails>
      </Box>
    </StyledContactBox>
  );
}

const StyledContactBox = styled(Box)(({ theme }) => ({
  flex: 1,
  backgroundColor: "#E0ECF4",
  borderRadius: "16px",
  padding: theme.spacing(2),
  paddingRight: "36px", // compensate phone icon on the left
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
  textAlign: "left",
  whiteSpace: "nowrap",
  width: "fit-content",
}));

const StyledContactDetails = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  display: "flex",
  flexDirection: "column",
}));

const StyledContactText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const StyledContactTextBold = styled(Typography)(({ theme }) => ({
  fontWeight: "600",
  marginBottom: theme.spacing(1.5),
}));

const StyledContactLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.main,
  fontWeight: "600",
  textDecoration: "none",
  "&:hover": {
    textDecoration: "none",
  },
}));
