import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Drawer,
  IconButton,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { ReactNode, Suspense } from "react";

type Props = {
  open: boolean;
  title: ReactNode | string;
  subtitle?: ReactNode | string;
  onClose: () => void;
  emphasizedContent?: ReactNode;
  children: ReactNode;
};

const border = "1px solid rgba(224, 224, 224, 1)";

export function DetailDrawer({
  open,
  title,
  subtitle,
  onClose,
  emphasizedContent,
  children,
}: Props) {
  return (
    <StyledDrawer
      anchor="right"
      variant="temporary"
      open={open}
      onClose={onClose}
    >
      <Suspense>
        <Stack
          direction="column"
          bgcolor="background.main"
          p={4}
          gap={4}
          borderBottom={border}
        >
          <Stack
            direction="row"
            gap={1}
            justifyContent="space-between"
            alignItems="start"
          >
            <Stack direction="column" gap={0.5}>
              <Typography
                pt={0.5}
                variant="h1"
                textOverflow="ellipsis"
                overflow="hidden"
              >
                {title}
              </Typography>
              {subtitle ? (
                <Typography
                  variant="body1"
                  textOverflow="ellipsis"
                  overflow="hidden"
                  color="rgba(102, 102, 102, 1)"
                >
                  {subtitle}
                </Typography>
              ) : null}
            </Stack>
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                backgroundColor: (theme) => theme.palette.grey[200],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          {emphasizedContent ? <Box>{emphasizedContent}</Box> : null}
        </Stack>
        <Box p={4}>{children}</Box>
      </Suspense>
    </StyledDrawer>
  );
}

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    width: "400px",
    overflowY: "auto",
    overflowX: "hidden",
    boxSizing: "border-box",
    border: "none",
    borderRadius: "18px 0 0 18px",
  },
}));
