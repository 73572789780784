import { keyframes, styled, useTheme } from "@mui/material";
import { type ReactElement, SVGProps } from "react";

export function ErrorAnimatedIcon(): ReactElement {
  const theme = useTheme();

  return <ErrorAnimatedSvg color={theme.palette.error.main}></ErrorAnimatedSvg>;
}

const ErrorSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 130.2 130.2"
    width={48}
    height={48}
    {...props}
  >
    <circle
      className="path circle"
      fill="none"
      stroke="currentColor"
      strokeWidth={6}
      strokeMiterlimit={10}
      cx={60}
      cy={60}
      r={57}
    />
    <line
      className="path line left-line"
      fill="none"
      stroke="currentColor"
      strokeWidth={6}
      strokeLinecap="round"
      strokeMiterlimit={10}
      x1={31.7}
      y1={35}
      x2={88.3}
      y2={85}
    />
    <line
      className="path line right-line"
      fill="none"
      stroke="currentColor"
      strokeWidth={6}
      strokeLinecap="round"
      strokeMiterlimit={10}
      x1={88.3}
      y1={35}
      x2={31.7}
      y2={85}
    />
  </svg>
);

const ErrorKeyframes = keyframes`
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
`;

const ErrorAnimatedSvg = styled(ErrorSvg)({
  "& .path": {
    "&.circle": {
      strokeDasharray: 1000,
      strokeDashoffset: 0,
      animationName: ErrorKeyframes,
      animationDuration: ".9s",
      animationTimingFunction: "ease-in-out",
    },
    "&.line": {
      strokeDasharray: 1000,
      strokeDashoffset: 1000,
      animationName: ErrorKeyframes,
      animationDuration: ".9s",
      animationTimingFunction: "ease-in-out",
      animationFillMode: "forwards",
    },
    "&.left-line": {
      animationDelay: ".35s",
    },
    "&.right-line": {
      animationDelay: ".55s",
    },
  },
});
