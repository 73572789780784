import { Box, styled } from "@mui/material";
import { keyframes } from "@mui/system";

export function CircleLoading({ ...rest }) {
  return <StyledBox {...rest}></StyledBox>;
}

const Rotate = keyframes`
  100%{transform: rotate(1turn)}
`;

const StyledBox = styled(Box)(() => ({
  width: "20px",
  height: "20px",
  borderRadius: "50%",
  background: `radial-gradient(farthest-side,#C4C4C4 94%,#0000)
    top/4px 4px no-repeat, conic-gradient(#0000 30%,#C4C4C4);
    -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 4px),#000 0)`,
  animation: Rotate,
  animationTimingFunction: "linear",
  animationIterationCount: "infinite",
  animationDuration: "1s",
}));
