import { IconChevronRight } from "@dfp/icons";
import {
  Avatar,
  Box,
  IconButton,
  Theme,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

import { VerticalContent } from "../VerticalContent/VerticalContent";

type Props = {
  avatar: React.ReactNode;
  title?: string;
  text: string | React.ReactNode;
  avatarSize?: keyof Theme["avatarSize"];
  buttonLink?: string;
};

export function ListItemWithAvatar({
  avatar,
  title,
  text,
  avatarSize = 48,
  buttonLink,
}: Props): React.ReactElement {
  return (
    <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
      <Box>
        <StyledAvatar size={avatarSize}>{avatar}</StyledAvatar>
      </Box>
      <VerticalContent gap={1}>
        {title ? <Typography variant="h4">{title}</Typography> : null}
        <Typography>{text}</Typography>
      </VerticalContent>
      {buttonLink ? (
        <Box sx={{ alignContent: "center" }}>
          <IconButton component={Link} to={buttonLink}>
            <IconChevronRight />
          </IconButton>
        </Box>
      ) : null}
    </Box>
  );
}

type StyledAvatarProps = {
  size: keyof Theme["avatarSize"];
};

const StyledAvatar = styled(Avatar)<StyledAvatarProps>(({ theme, size }) => {
  return {
    ...theme.avatarSize[size],
    color: theme.palette.accent.main,
    backgroundColor: theme.palette.accent.light,
    border: "none",
    fontSize: size / 1.8,
  };
});
