import { Alert, Snackbar, SnackbarProps, useTheme } from "@mui/material";
import { createContext, useMemo, useState } from "react";

const autoHideDuration = 6 * 1000;

export type SnackbarSeverity = "warning" | "info" | "success" | "error";

export type SnackbarContext = {
  showSnackbar: (message: string, severity: SnackbarSeverity) => void;
  showErrorSnackbar: (error: Error) => void;
};

export const SnackbarContext = createContext<SnackbarContext | undefined>(
  undefined
);

export type SnackbarMessage =
  | {
      key: number;
      message: string;
      severity: SnackbarSeverity;
    }
  | {
      key: number;
      error: Error;
      severity: "error";
    };

type Props = {
  children: React.ReactNode | React.ReactNode[];
  translateError: (error: Error) => string;
};

export type State = {
  message?: SnackbarMessage;
};

export function SnackbarProvider({ children, translateError }: Props) {
  const theme = useTheme();

  const [message, setMessage] = useState<SnackbarMessage | undefined>(
    undefined
  );

  const handleSnackbarClose: SnackbarProps["onClose"] = (event, reason) => {
    if (reason === "timeout") {
      setMessage(undefined);
    }
  };

  const handleClickClose = () => {
    setMessage(undefined);
  };

  const contextValue = useMemo(
    () => ({
      showSnackbar: (message: string, severity: SnackbarSeverity) => {
        setMessage({ message, severity, key: Date.now() });
      },
      showErrorSnackbar: (error: Error) => {
        setMessage({ error, severity: "error", key: Date.now() });
      },
    }),
    []
  );

  return (
    <SnackbarContext.Provider value={contextValue}>
      {children}
      <Snackbar
        key={message?.key}
        open={!!message}
        autoHideDuration={autoHideDuration}
        onClose={handleSnackbarClose}
        sx={{
          minWidth: "30%",
          backgroundColor: theme.palette.background.paper,
          borderRadius: 4.5,
        }}
      >
        {message && (
          <Alert
            onClose={handleClickClose}
            severity={message.severity}
            sx={{ width: "100%" }}
          >
            {"error" in message
              ? translateError(message.error)
              : message.message}
          </Alert>
        )}
      </Snackbar>
    </SnackbarContext.Provider>
  );
}
