import type { ReactNode } from "react";

function emptyToNull(str: unknown): string | null {
  if (typeof str === "string" && str !== "") {
    return str;
  }

  return null;
}

export function getHelperText(
  helperText: string | ReactNode,
  errorMessage: string | undefined,
  readOnly: boolean | undefined
): string | ReactNode {
  if (readOnly === true) {
    return transformHelper(helperText);
  }

  if (typeof errorMessage === "string" && errorMessage !== "") {
    return errorMessage;
  }

  return transformHelper(helperText);
}

function transformHelper(helper: string | ReactNode) {
  return typeof helper === "string" ? emptyToNull(helper) : helper;
}
