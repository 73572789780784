import { Box, styled } from "@mui/material";
import { ReactNode } from "react";

type Props = {
  children?: ReactNode;
};
export function VerticalLineBullet({ children }: Props) {
  return (
    <Box display="flex" flexDirection="row">
      <Box flex="16px 0 0" display="flex" flexDirection="column">
        <Line />
        <Bullet />
        <Line />
      </Box>
      <Box flex="auto 1 1">{children}</Box>
    </Box>
  );
}

const Line = styled("div")(() => ({
  flex: "10px 1 1",
  borderLeft: "2px solid #0000001A",
  marginLeft: "7px",
}));

const Bullet = styled("div")(() => ({
  flex: "8px 0 0",
  margin: "4px",
  border: "2px solid #0000001A",
  borderRadius: "50%",
}));

export const VerticalLineSpacer = styled("div")(() => ({
  flex: "10px 0 0",
  height: "10px",
  borderLeft: "2px solid #0000001A",
  marginLeft: "7px",
}));
