import { IconInfoCircle } from "@dfp/icons";
import {
  Box,
  FormControl,
  FormHelperText,
  type FormHelperTextProps,
  FormLabel,
  IconButton,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { getHelperText } from "./helper";

export type FieldWrapperProps = {
  name: string;
  label: string | undefined;
  labelId: string;
  labelHelpText: string | undefined;
  helperText: string | ReactNode | undefined;
  errorMessage: string | undefined;
  children: ReactNode;
  readOnly: boolean | undefined;
  required: boolean | undefined;
  helperTextProps: FormHelperTextProps | undefined;
};
export function FieldWrapper({
  label,
  labelId,
  labelHelpText,
  helperText,
  readOnly,
  required,
  errorMessage,
  children,
  helperTextProps,
}: FieldWrapperProps) {
  const { t } = useTranslation("common");

  const helper = getHelperText(helperText, errorMessage, readOnly);

  return (
    <StyledFormControl
      disabled={readOnly === true}
      error={typeof errorMessage === "string" && readOnly !== true}
      fullWidth
      required={required}
      variant="outlined"
      size="medium"
    >
      {label ? (
        <FormLabel
          required={false}
          aria-required={required}
          sx={{ display: "inline-flex", gap: 1 }}
        >
          <Typography id={labelId} component="span">
            {label}
          </Typography>
          {!required && !readOnly && (
            <Typography component="span" color="secondary">
              {/* Keeping here translation backup mainly for Storybook */}
              {t("Input.optional", "(Optional)")}
            </Typography>
          )}
          {labelHelpText ? (
            <Tooltip title={labelHelpText} placement="top">
              <IconButton
                size="small"
                disableRipple
                focusRipple
                sx={{ px: 0, py: 0, bottom: "-1px" }}
              >
                <IconInfoCircle width={16} height={16} />
              </IconButton>
            </Tooltip>
          ) : null}
        </FormLabel>
      ) : null}
      <Box flex={1}>
        {children}
        {helper && (
          <FormHelperText {...helperTextProps}>{helper}</FormHelperText>
        )}
      </Box>
    </StyledFormControl>
  );
}

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  flexDirection: "column",
  ":not(:last-child)": {
    marginBottom: theme.spacing(2),
  },
  "& > .MuiFormLabel-root": {
    fontSize: theme.typography.body2.fontSize,
    marginBottom: theme.spacing(0.5),
  },
}));
