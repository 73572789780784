import { Box, Typography, styled } from "@mui/material";
import * as polished from "polished";

type Props = {
  number: number;
  text: string;
};

export function ListItemWithNumber({ number, text }: Props) {
  return (
    <StyledNumberListItem>
      <StyledNumberBulletPoint>{number}</StyledNumberBulletPoint>
      <Typography>{text}</Typography>
    </StyledNumberListItem>
  );
}

const StyledNumberListItem = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: theme.spacing(1),
}));

const StyledNumberBulletPoint = styled(Box)(({ theme }) => ({
  color: theme.palette.accent.main,
  backgroundColor: theme.palette.accent.light,
  padding: "3px 8px",
  lineHeight: polished.rem(18),
  borderRadius: 6,
  fontSize: polished.rem(13),
}));
