import dayjs from "dayjs";
import "dayjs/locale/cs";
import "dayjs/locale/en";
import localizedFormat from "dayjs/plugin/localizedFormat";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import updateLocale from "dayjs/plugin/updateLocale";

dayjs.extend(quarterOfYear);
dayjs.extend(updateLocale);
dayjs.extend(localizedFormat);

dayjs.updateLocale("cs", {
  formats: {
    L: "DD.MM.YYYY",
    LL: "MMMM YY",
  },
});

dayjs.updateLocale("en", {
  formats: {
    L: "DD/MM/YYYY",
    LL: "MMMM YY",
  },
});

export const dayjsIntegration = {};
