import { IconUser03 } from "@dfp/icons";
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemProps,
  ListItemText,
  Theme,
  css,
  styled,
} from "@mui/material";
import React, { ReactNode } from "react";

type Props = {
  avatarSize?: keyof Theme["avatarSize"];
  primary: ReactNode;
  secondary?: ReactNode;
} & ListItemProps;

export function ListItemPerson({
  avatarSize = 40,
  primary,
  secondary,
  ...rest
}: Props) {
  return (
    <ListItem {...rest}>
      <StyledListItemAvatar>
        <StyledAvatar size={avatarSize}>
          {React.createElement(IconUser03, { fontSize: avatarSize / 2 })}
        </StyledAvatar>
      </StyledListItemAvatar>

      <StyledListItemText
        primary={primary}
        secondary={secondary}
        secondaryTypographyProps={{ component: "div" }}
      />
    </ListItem>
  );
}

type StyledAvatarProps = {
  size: keyof Theme["avatarSize"];
  children: ReactNode;
};

const StyledAvatar = styled(Avatar)<StyledAvatarProps>(
  ({ theme, size }) => css`
    ${theme.avatarSize[size]}
    color: ${theme.palette.text.main};
    background: ${theme.palette.grey[200]};
    border: none;
  `
);

const StyledListItemText = styled(ListItemText)({
  "& span": {
    fontWeight: 500,
  },
});

const StyledListItemAvatar = styled(ListItemAvatar)(({ theme }) => ({
  minWidth: 0,
  marginRight: theme.spacing(2),
}));
