import { Box, SxProps, Theme, styled } from "@mui/material";
import { ReactElement } from "react";

type Props = {
  sx?: SxProps<Theme>;
};

export function HeaderDivider({ sx }: Props): ReactElement {
  return (
    <StyledHeaderDivider
      sx={{
        ...sx,
      }}
    />
  );
}

const StyledHeaderDivider = styled(Box)(({ theme }) => ({
  width: "1px",
  height: theme.spacing(5),
  backgroundColor: theme.palette.grey[500],
  background:
    "linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(0,0,0,0.2) 50%, rgba(255,255,255,1) 100%)",
}));
