import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IconButton, IconButtonProps, Theme, styled } from "@mui/material";
import { ReactElement } from "react";

type Props = {
  expanded: boolean;
  theme?: Omit<Theme, "components">;
} & IconButtonProps;

const StyledIcon = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== "expanded",
})(({ expanded, theme }: Props) => ({
  transform: `rotate(${expanded ? "180deg" : "0deg"})`,
  transition: theme?.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
  color: "primary.main",
}));

export function ControlIconExpand({ expanded, ...rest }: Props): ReactElement {
  return (
    <StyledIcon
      aria-expanded={expanded}
      aria-label="show more"
      size="small"
      expanded={expanded}
      {...rest}
    >
      <ExpandMoreIcon />
    </StyledIcon>
  );
}
