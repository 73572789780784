import { Box, Typography, styled } from "@mui/material";
import * as polished from "polished";
import { ReactElement } from "react";
import { Link } from "react-router-dom";

export type AppLogoProps = {
  url?: string | null;
  openInNewTab?: boolean;
} & LogoElementProps;

export function AppLogo({
  url,
  openInNewTab = false,
  ...rest
}: AppLogoProps): ReactElement {
  if (url) {
    return (
      <Box
        display="flex"
        alignItems="center"
        component={Link}
        to={url}
        target={openInNewTab ? "_blank" : "_self"}
        sx={{
          textDecoration: "none",
          "& :link": {
            textDecoration: "none",
          },
        }}
      >
        <LogoElement {...rest} />
      </Box>
    );
  }

  return (
    <Box display="flex" alignItems="center">
      <LogoElement {...rest} />
    </Box>
  );
}

type LogoElementProps = {
  height?: number;
  logoTitle?: React.ReactNode | string;
  logoTitleAdditional?: React.ReactNode | string;
  svg?: string;
};

function LogoElement({
  height = 20,
  logoTitle,
  logoTitleAdditional,
  svg,
}: LogoElementProps): ReactElement {
  return (
    <Box display="flex" alignItems="center">
      {svg && (
        <img
          src={`/assets/logo/${svg}`}
          style={{
            height,
          }}
          alt="Fidoo logo"
        />
      )}
      <Box sx={{ ml: 0.5 }}>
        {logoTitle && <StyledTitle>{logoTitle}</StyledTitle>}
        {logoTitleAdditional && (
          <StyledTitleAdditional>{logoTitleAdditional}</StyledTitleAdditional>
        )}
      </Box>
    </Box>
  );
}

const StyledTitle = styled(Typography)(({ theme }) => ({
  display: "block",
  fontSize: polished.rem(20),
  lineHeight: 1,
  fontFamily: "Poppins",
  color: "#BECD00",
  textDecoration: "none",
  "&:hover": {
    color: "#BECD00",
    textDecoration: "none",
  },
  "&:only-child": {
    color: theme.palette.text.main,
  },
  "&:only-child:hover": {
    color: theme.palette.text.main,
  },
}));

const StyledTitleAdditional = styled(Typography)(({ theme }) => ({
  display: "block",
  fontSize: polished.rem(20),
  lineHeight: 1,
  fontFamily: "Poppins",
  color: theme.palette.text.main,
  textDecoration: "none",
  "&:hover": {
    color: theme.palette.text.main,
    textDecoration: "none",
  },
}));
