import { keyframes, styled, useTheme } from "@mui/material";
import { type ReactElement, SVGProps } from "react";

export function SuccessAnimatedIcon(): ReactElement {
  const theme = useTheme();

  return (
    <SuccessAnimatedSvg color={theme.palette.success.main}></SuccessAnimatedSvg>
  );
}

const SuccessSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 120 120"
    width={48}
    height={48}
    {...props}
  >
    <circle
      className="path circle"
      fill="none"
      stroke="currentColor"
      strokeWidth={6}
      strokeMiterlimit={10}
      cx={60}
      cy={60}
      r={57.2}
    />
    <polyline
      className="path check"
      fill="none"
      stroke="currentColor"
      strokeWidth={6}
      strokeLinecap="round"
      strokeMiterlimit={10}
      points="92.4,37 47.5,81.8 27.4,62.2"
    />
  </svg>
);

const DashCheckKeyframes = keyframes`
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
`;

const DashCircleKeyframes = keyframes`
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
`;

const SuccessAnimatedSvg = styled(SuccessSvg)({
  "& .path": {
    "&.circle": {
      strokeDasharray: 1000,
      strokeDashoffset: 0,
      animationName: DashCircleKeyframes,
      animationDuration: ".9s",
      animationTimingFunction: "ease-in-out",
    },
    "&.check": {
      strokeDasharray: 1000,
      strokeDashoffset: -100,
      animationName: DashCheckKeyframes,
      animationDuration: ".9s",
      animationDelay: ".35s",
      animationTimingFunction: "ease-in-out",
      animationFillMode: "forwards",
    },
  },
});
