import { ArrowBack } from "@mui/icons-material";
import { Button, styled } from "@mui/material";
import { ReactElement } from "react";
import { Link } from "react-router-dom";

export type PageBackButtonProps = {
  buttonText: string;
  linkTo: string;
};
export function PageBackButton({
  buttonText,
  linkTo,
}: PageBackButtonProps): ReactElement {
  return (
    <Button
      variant="text"
      startIcon={<ArrowBack sx={{ fontSize: "13" }} />}
      component={StyledLink}
      to={linkTo}
      size="small"
      sx={{ textTransform: "none !important", alignSelf: "start" }}
    >
      {buttonText}
    </Button>
  );
}

const StyledLink = styled(Link, {
  // these props are not supported by the Link component
  shouldForwardProp: (prop) =>
    prop !== "focusRipple" && prop !== "focusVisibleClassName",
})(() => ({
  display: "inline-flex",
  flexDirection: "row",
  alignItems: "center",
  textDecoration: "none !important",
  fontSize: "13px !important",
  marginLeft: "-16px",

  ".MuiSvgIcon-root": {
    fontSize: "15px !important",
    width: "16px !important",
    height: "16px !important",
  },
}));
